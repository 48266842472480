<template>
  <v-dialog v-model="value" @click:outside="_close" max-width="600">
    <v-card :loading="loading">
      <v-form
        ref="form"
        v-model="valid"
        :lazy-validation="false"
        @submit.prevent="_filter"
      >
        <v-card-title class="headline">Filter</v-card-title>

        <v-card-text>
          <v-text-field v-model="search" label="Search"></v-text-field>

          <v-row>
            <v-col cols="6">
              <v-text-field type="number" v-model="nostart" label="Start No."></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field type="number" v-model="noend" label="End No."></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="6">
              <v-menu
                v-model="dstart"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="start"
                    label="Start Date"
                    clearable
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    @click:clear="start = null"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="start"
                  @input="dstart = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="6">
              <v-menu
                ref="dtstart"
                v-model="dtstart"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="tstart"
                transition="scale-transition"
                offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="tstart"
                    label="Start time"
                    prepend-icon="mdi-clock-time-four-outline"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="dtstart"
                  v-model="tstart"
                  full-width
                  @click:minute="$refs.dtstart.save(tstart)"
                ></v-time-picker>
              </v-menu>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="6">
              <v-menu
                v-model="dend"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="end"
                    label="End Date"
                    clearable
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    @click:clear="end = null"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="end"
                  @input="dend = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="6">
              <v-menu
                ref="dtend"
                v-model="dtend"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="tend"
                transition="scale-transition"
                offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="tend"
                    label="End time"
                    prepend-icon="mdi-clock-time-four-outline"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="dtend"
                  v-model="tend"
                  full-width
                  @click:minute="$refs.dtend.save(tend)"
                ></v-time-picker>
              </v-menu>
            </v-col>
          </v-row>

          <v-row v-for="(i, k) in items" :key="k">
            <v-col cols="6">
              <v-combobox
                v-model="i.field"
                :items="field"
                item-text="name"
                item-value="name"
                label="Field"
                clearable
                return-object
                @change="_fieldChange(k, i)"
              >
              </v-combobox>
            </v-col>
            <v-col cols="3" v-if="i.field">
              <v-combobox
                v-model="i.operator"
                :items="i.operators"
                label="Operator"
                return-object
                @change="_operatorChange(k, i)"
              >
              </v-combobox>
            </v-col>
            <v-col cols="3" v-if="_visibleValue(i)">
              <v-text-field
                v-model="i.value"
                label="Value"
                v-if="!i.choices"
              ></v-text-field>
              <v-combobox
                v-if="i.choices"
                v-model="i.value"
                :items="i.choices"
                label="Value"
                :return-object="false"
              >
              </v-combobox>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="error darken-1" text @click="_close">Close</v-btn>

          <v-btn color="primary darken-1" text @click="_clear">Clear</v-btn>

          <v-btn color="green darken-1" text type="submit">Filter</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>
<script>
import _ from 'lodash';

const operator = [
  {
    text: "is empty",
    value: "is empty",
  },
  {
    text: "is not empty",
    value: "is not empty",
  },
  {
    text: "=",
    value: "=",
  },
  {
    text: "!=",
    value: "!=",
  },
  {
    text: "contains",
    value: "contains",
  },
  {
    text: "not contains",
    value: "not contains",
  },
  {
    text: ">",
    value: ">",
  },
  {
    text: "<",
    value: "<",
  },
  {
    text: ">=",
    value: ">=",
  },
  {
    text: "<=",
    value: "<=",
  },
];

export default {
  components: {},
  props: ["value", "fields"],
  data: () => {
    return {
      loading: false,
      valid: false,
      search: "",
      start: null,
      end: null,
      tstart: null,
      tend: null,
      field: [],
      items: [{}],
      dstart: false,
      dend: false,
      dtstart: false,
      dtend: false,
      nostart: null,
      noend: null,
    };
  },
  watch: {
    fields: {
      handler(val) {
        if (val && Array.isArray(val)) {
          this._genFields(val);
        }
      },
      deep: false,
    },
    start: {
      handler() {
        if(!this.tstart) {
          this.tstart = "00:00";
        }
      },
      deep: false,
    },
    end: {
      handler() {
        if(!this.tend) {
          this.tend = "23:59";
        }
      },
      deep: false,
    },
  },
  methods: {
    _genFields(data) {
      this.field = data.reduce((o, e) => {
        // Type
        switch (e.type) {
          case "matrixdropdown":
            o = e.columns.reduce((oo, ee) => {
              o.push({
                ...ee,
                p_rows: e.rows,
                p_columns: e.columns,
                p_choices: e.choices,
                name: `${e.name}.$.${ee.name}`,
              });

              return oo;
            }, o);
            break;

          case "multipletext":
            o = e.items.reduce((oo, ee) => {
              o.push({
                ...ee,
                name: `${e.name}.${ee.name}`,
              });

              return oo;
            }, o);
            break;

          default:
            o.push(e);
            break;
        }

        // Approve
        if (e.approve) {
          o.push({
            ...e,
            choices: [true, false],
            name: `approve.${e.name}`,
            isApprove: true,
          });
        }

        // Todo Comment
        if (e.hasOther === true) {
          o.push({
            ...e,
            choices: undefined,
            name: `${e.name}-Comment`,
          });
        }

        return o;
      }, []);
    },
    _fieldChange(index, data) {
      const isApprove = _.get(data, "field.isApprove");
      const choices = _.get(data, "field.choices");
      const type = _.get(data, "field.type");

      // operator
      if (isApprove) {
        data.operators = operator.slice(2, 3);
      } else {
        if (type === "file" || type === "signaturepad") {
          data.operators = operator.slice(0, 2);
        } else {
          if (choices && choices.length > 0 && type !== "imagepicker") {
            data.operators = operator.slice(0, 4);
          } else {
            data.operators = operator;
          }
        }
      }
      data.operator = data.operators[0];
      data.value = false;

      // choices
      if (choices && choices.length > 0 && type !== "imagepicker") {
        data.choices = choices;
      } else {
        data.choices = undefined;
      }

      // new condition
      const len = this.items.length;
      if (index === len - 1 && data.field) {
        this.items.push({});
      }
    },
    _operatorChange(index, data) {
      this.loading = true;
      const operator = data.operator;

      if (operator.value === "is empty") {
        data.value = false;
      } else if (operator.value === "is not empty") {
        data.value = true;
      } else if (
        operator.value === "contains" ||
        operator.value === "not contains"
      ) {
        data.choices = undefined;
        data.value = "";
      }

      data.value = typeof data.value === "boolean" ? "" : data.value;

      this.$nextTick(() => {
        this.loading = false;
      });
    },
    _visibleValue(data) {
      if (
        data.field &&
        data.operator.value !== "is empty" &&
        data.operator.value !== "is not empty"
      ) {
        return true;
      }

      return false;
    },
    _close() {
      this.$emit("input", false);
    },
    _clear() {
      this.items = [{}];
      this.$refs.form.reset();
    },
    _filter() {
      let cond = "$eq";

      const filter = this.items.reduce((o, e) => {
        if (e.operator && e.field) {
          switch (e.operator.value) {
            // is empty
            case "is empty":
              cond = "$exists";
              e.value = false;
              break;
            // is not empty
            case "is not empty":
              cond = "$exists";
              e.value = true;
              break;
            // Not equal
            case "!=":
              cond = "$ne";
              break;
            // Greater more than
            case ">":
              cond = "$gt";
              break;
            // Greater more than equal
            case ">=":
              cond = "$gte";
              break;
            // Less more than
            case "<":
              cond = "$lt";
              break;
            // Less more than equal
            case "<=":
              cond = "$lte";
              break;
            // Contains
            case "contains":
              cond = "$ct";
              break;
            // Not Contains
            case "not contains":
              cond = "$nct";
              break;
            // Equal
            default:
              cond = "$eq";
              break;
          }

          o = [...o, ...this.genCondition(e, cond)];
        }

        return o;
      }, []);

      this.$emit("click", {
        search: this.search,
        start: this.start && `${this.start} ${this.tstart}`,
        end: this.end && `${this.end} ${this.tend}`,
        nostart: parseInt(this.nostart) || null,
        noend: parseInt(this.noend) || null,
        filter: filter,
      });
    },
    genCondition(data, operator = "$eq") {
      let arr = [];
      let field = `answer.${(data.field && data.field.name) || ""}`;
      const p_rows = data.field && data.field.p_rows;
      let value =
        typeof data.value === "boolean" ? data.value : +data.value;
      if (isNaN(value)) {
        value = data.value;
      }

      // Contains
      if (operator === "$ct") {
        return this.genContainsCondition(field, "regex", value);
      }

      // Not Contains
      if (operator === "$nct") {
        return this.genNotContainsCondition(field, "nregex", value);
      }

      // Approve
      if (/^answer.approve/.test(field)) {
        field = (data.field && data.field.name) || "";
        return this.genApproveCondition(field, operator, value);
      }

      // Simple
      if (p_rows) {
        const rows = p_rows.reduce((o, e) => {
          const frows = field.replace("$", e.value || "value");

          o.push({
            [frows]: {
              [operator]: value,
            },
          });

          return o;
        }, []);

        arr = [{ $or: rows }];
      } else {
        arr.push({
          [field]: {
            [operator]: value,
          },
        });
      }

      return arr;
    },
    genApproveCondition(field, operator, value) {
      const arr = [];
      const key = `${field}.status`;

      if (value) {
        arr.push({
          [key]: {
            [operator]: value,
          },
        });
      } else {
        arr.push({
          $or: [
            {
              [key]: {
                [operator]: value,
              },
            },
            {
              [key]: {
                $exists: false,
              },
            },
          ],
        });
      }

      return arr;
    },
    genContainsCondition(field, operator, value) {
      return [
        {
          [field]: {
            [operator]: `${value}`,
          },
        },
      ];
    },
    genNotContainsCondition(field, operator, value) {
      return [
        {
          [field]: {
            [operator]: `${value}`,
          },
        },
      ];
    },
  },
};
</script>
<style scoped>
.col {
  padding: 0 12px !important;
}
</style>