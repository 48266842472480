<template>
  <v-container fluid>
    <!-- Filter Dialog -->
    <filters
      v-model="dialogFilter"
      :fields="Object.values(fields)"
      @click="_filter"
    />
    <!-- List Table -->
    <v-data-table
      :headers="headers"
      :items="items"
      :footer-props="{ 'items-per-page-options': $itemsPerPage }"
      :items-per-page="perpage"
      :single-select="singleSelect"
      v-model="selected"
      :show-select="showSelect && !isViewer"
      class="elevation-1 mt-5 approve-tb"
      :loading="loading"
      :options.sync="options"
      :server-items-length="countItems"
      loading-text="Loading... Please wait"
    >
      <template v-slot:[`item.editableCMS`]="{ item }">
        <v-icon title="edit" @click="_edit(item)" class="mr-1"
          >mdi-circle-edit-outline</v-icon
        >
        <v-icon
          v-if="$rolesUser[$profile.role] === $rolesUser['super-admin']"
          title="delete"
          @click="_delete(item)"
          :disabled="isViewer"
          class="mr-1"
          >mdi-delete-circle-outline</v-icon
        >
      </template>

      <template v-slot:[`item.created_date`]="{ item }">
        {{ createdToDate(item.created_at) }}
      </template>

      <template v-slot:[`item.created_time`]="{ item }">
        {{ createdToTime(item.created_at) }}
      </template>

      <template v-slot:top>
        <v-toolbar flat color="white" class="text-right ml-12">
          <div v-if="showSelect && !isViewer">
            <v-btn
              :disabled="selected.length === 0"
              color="success"
              class="mr-1"
              @click="_dialogApproveBulk"
              >Approve</v-btn
            >
            <v-btn
              :disabled="selected.length === 0"
              color="error"
              @click="_dialogRejectBulk"
              >Reject</v-btn
            >
          </div>
          <v-spacer></v-spacer>
          <v-text-field
            v-on:keyup.enter="gets"
            v-model="filter.search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
          <v-icon
            title="approve"
            class="ml-3"
            :color="filterColor"
            @click="dialogFilter = true"
          >
            mdi-filter
          </v-icon>
          <v-menu offset-y class="ml-3">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                title="export"
                class="ml-3"
                size="28"
                v-bind="attrs"
                v-on="on"
              >
                mdi-download-circle-outline
              </v-icon>
            </template>
            <v-list>
              <v-list-item title="excel" @click="_exportExcel()">
                <v-list-item-icon>
                  <v-icon class="ml-3" size="28" color="green">
                    mdi-microsoft-excel
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-title class="green--text">
                  Excel
                </v-list-item-title>
              </v-list-item>
              <v-list-item title="json" @click="_export('json')">
                <v-list-item-icon>
                  <v-icon class="ml-3" size="28" color="orange">
                    mdi-code-json
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-title class="orange--text">
                  JSON
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-toolbar>
      </template>

      <template v-slot:[`item.answer.${i.name}`]="{ item }" v-for="i in custom">
        <div class="image" :key="i.name">
          <img
            v-if="_isImg(i, item.answer[i.name], item.files[i.name])"
            :src="_imgSrc(i, item.answer[i.name], item.files[i.name])"
            height="150"
            loading="lazy"
            @click.prevent="_image(item, i)"
          />
          <a
            v-else-if="_isFile(i, item.answer[i.name], item.files[i.name])"
            @click.prevent="_downloadFile(item.files[i.name])"
          >
            Download
          </a>
          <img
            v-else-if="i.input === 'signaturepad'"
            :src="item.answer[i.name]"
            height="150"
            loading="lazy"
          />
          <div v-else v-html="_generateAnswer(i, item.answer)"></div>
          <div class="overlay pb-2" v-if="i.approve">
            <v-icon
              title="approve"
              color="green"
              class="items-icon mr-1"
              :class="{
                unactive: !(
                  item.approve &&
                  item.approve[i.name] &&
                  item.approve[i.name] &&
                  item.approve[i.name].status === true
                ),
              }"
              @click="_approve(item, i)"
            >
              mdi-check-circle
            </v-icon>
            <v-icon
              title="reject"
              color="red"
              class="items-icon disabled"
              :class="{
                unactive: !(
                  item.approve &&
                  item.approve[i.name] &&
                  item.approve[i.name] &&
                  item.approve[i.name].status === false
                ),
              }"
              @click="_reject(item, i)"
            >
              mdi-close-circle
            </v-icon>
          </div>
        </div>
      </template>
    </v-data-table>
    <!-- Image Dialog -->
    <v-dialog v-model="dialogImage" max-width="800">
      <v-card>
        <v-card-text>
          <v-carousel
            hide-delimiter-background
            show-arrows-on-hover
            class="pt-5"
            v-model="slide"
          >
            <v-carousel-item v-for="(slide, i) in slides" :key="i">
              <v-sheet color="white" height="100%">
                <v-row class="fill-height" align="center" justify="center">
                  <img :src="slide" class="image-slide" />
                </v-row>
              </v-sheet>
            </v-carousel-item>
          </v-carousel>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Approve Dialog -->
    <v-dialog v-model="dialogApprove" max-width="600">
      <v-card>
        <v-card-title class="headline">Do you want to approve?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error darken-1" text @click="dialogApprove = false"
            >Cancel</v-btn
          >
          <v-btn color="green darken-1" text type="submit" @click="approve"
            >Approve</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Reject Dialog -->
    <v-dialog v-model="dialogReject" max-width="600">
      <v-card>
        <v-form
          ref="form"
          v-model="valid"
          :lazy-validation="false"
          @submit.prevent="reject"
        >
          <v-card-title class="headline">Do you want to reject?</v-card-title>

          <v-card-text>
            <!-- <v-text-field
              v-if="item.data.id"
              v-model="item.data.id"
              :rules="[rules.required]"
              label="ID"
              :disabled="true"
            ></v-text-field> -->

            <v-textarea
              label="Reason"
              v-model="item.reason"
              :rules="[rules.required]"
            >
            </v-textarea>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="error darken-1" text @click="_cancelReject"
              >Cancel</v-btn
            >

            <v-btn color="green darken-1" text type="submit" :disabled="!valid"
              >Reject</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <!-- Confirm -->
    <v-dialog v-model="confirm" max-width="400">
      <v-card>
        <v-card-title class="headline">{{
          'Do you want to delete?'
        }}</v-card-title>

        <v-card-text></v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="error darken-1" text @click="confirm = false"
            >Cancel</v-btn
          >

          <v-btn color="green darken-1" text @click="_confirm">Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Export options -->
    <v-dialog v-model="exportOptions.modal" max-width="400">
      <v-card>
        <v-form
          v-model="valid"
          :lazy-validation="false"
          @submit.prevent="_export('excel')"
        >
          <v-card-title class="headline">Export Options</v-card-title>

          <v-card-text>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  v-model="exportOptions.separator"
                  :rules="[rules.required]"
                  label="Seperator"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="exportOptions.bracket"
                  label="Bracket"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              color="error darken-1"
              text
              @click="exportOptions.modal = false"
              >Cancel</v-btn
            >

            <v-btn color="green darken-1" text type="submit" :disabled="!valid"
              >Export</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import moment from 'moment'
import _ from 'lodash'

import SurveyAPI from '@/store/models/Survey'
import File from '@/store/models/File'

import Filters from '@/components/Filters'

export default {
  components: {
    Filters,
  },
  data: () => {
    return {
      rules: {
        required: (value) => !!value || 'Required.',
      },
      items: [],
      showSelect: false,
      singleSelect: false,
      selected: [],
      form: {},
      customKey: {
        image: true,
        file: true,
        signaturepad: true,
        matrix: true,
        matrixdropdown: true,
        matrixdynamic: true,
        multipletext: true,
        checkbox: true,
        tagbox: true,
      },
      custom: {},
      options: {},
      perpage: 1,
      countItems: 0,
      loading: false,
      dialog: false,
      save: false,
      confirm: false,
      id: null,
      dialogApprove: false,
      dialogReject: false,
      item: {
        data: {},
      },
      valid: false,
      dialogImage: false,
      cOrder: [],
      slide: 0,
      slides: [],
      dialogFilter: false,
      fields: {},
      filter: {},
      filterItem: [{}],
      formSurvey: {},
      projName: 'survey',
      formName: 'form',
      editableCMS: false,
      exportOptions: {
        modal: false,
        separator: ',',
        bracket: '',
      },
    }
  },
  created() {
    this.id = this.$route.params.id
    this.gets()
  },
  watch: {
    options: {
      handler() {
        this.gets()
      },
      deep: false,
    },
  },
  computed: {
    projId() {
      return this.$store
        .$db()
        .model('projId')
        .find(1)
    },
    isViewer() {
      const role = _.get(this.projId, 'access.role')
      const mrole = _.get(this.$rolesMember, '[1].value')
      return role && role === mrole
    },
    headers() {
      // this.cOrder = [];
      const pages = _.get(this.formSurvey, 'json.pages')
      if (pages) {
        const init = [
          {
            text: 'No',
            value: 'no',
            align: 'left',
            sortable: false,
          },
          {
            text: 'Created Date',
            value: 'created_date',
            align: 'left',
            sortable: false,
          },
          {
            text: 'Created Time',
            value: 'created_time',
            align: 'left',
            sortable: false,
          },
        ]
        const editable = this.editableCMS

        if (editable && !this.isViewer) {
          init.unshift({
            text: 'Edit',
            value: 'editableCMS',
            align: 'center',
            sortable: false,
          })
        }
        const items = pages.reduce((o, e) => {
          o = this.setHeaders(o, e)

          return o
        }, init)

        return items
      } else {
        return [
          {
            text: 'Name',
            value: 'name',
            align: 'left',
            sortable: false,
          },
          {
            text: 'Callback',
            value: 'callback',
            sortable: false,
          },
          {
            text: 'Action',
            value: 'action',
            sortable: false,
            align: 'center',
          },
        ]
      }
    },
    reason() {
      return this.item.reason
    },
    filterColor() {
      const filter = this.filter
      if (
        filter.start ||
        filter.end ||
        filter.nostart ||
        filter.noend ||
        filter.search ||
        filter.length > 0
      ) {
        return 'green'
      }
      return ''
    },
  },
  methods: {
    params(json = true) {
      let params = {}

      // Per page
      if (this.options && this.options.itemsPerPage) {
        params.perpage = this.options.itemsPerPage
      }

      // Page
      if (this.options && this.options.page) {
        params.page = this.options.page
      }

      // Sort
      params.sort = {}

      // Search
      params.query = this.filter.search

      // no range
      let start = this.filter.nostart
      if (start) {
        start = (params.page - 1) * params.perpage + start
      }
      params.start = start
      params.end = this.filter.noend
      if (!json) { // Export excel and json
        params.perpage = 0
      }

      // Date range
      params.startdate =
        this.filter.start &&
        moment(`${this.filter.start}`, 'YYYY-MM-DD HH:mm').toDate()
      params.enddate =
        this.filter.end &&
        moment(`${this.filter.end}`, 'YYYY-MM-DD HH:mm').toDate()

      // Condition
      const filter = this.filter && this.filter.filter
      if (Array.isArray(filter) && filter.length > 0) {
        params.condition = { $and: filter }
      }

      return params
    },
    gets() {
      const params = this.params()

      if (params.page && params.perpage && this.id) {
        this.loading = true

        // API
        const options = { params }
        SurveyAPI.gets(this.id, options)
          .then(async (res) => {
            const cal = (params.page - 1) * params.perpage
            // no
            let start = cal
            if (params.start > 0) {
              start = params.start - 1
            }

            this.formSurvey = res.form || {}
            this.formName = (res.form && res.form.name) || 'form'
            this.projName =
              (res.form && res.form.project && res.form.project.name) ||
              'survey'
            this.editableCMS = (res.form && res.form.editableCMS) || false

            // list
            this.items =
              res.data.map((e, k) => {
                e.no = start + k + 1
                return e
              }) || []

            // count
            this.countItems = res.count || 0
            if (params.start > 0) {
              if (params.end > 0) {
                this.countItems = params.end - params.start + 1
              } else {
                this.countItems = res.count - (params.start - cal) + 1
              }
            } else if (params.end > 0) {
              if (res.count > params.end) {
                this.countItems = params.end
              } else {
                this.countItems = res.count
              }
            }
            this.loading = false
            return this.items
          })
          .catch((err) => {
            this.loading = false
            console.error('Get surveys err: ', err)
          })
      }
    },
    setHeaders(o = [], e = {}) {
      const res =
        e.elements &&
        e.elements.reduce((oo, ee) => {
          ee.input = this.inputType(ee)

          // custom
          let align = 'left'
          if (ee.approve || this.customKey[ee.input]) {
            align = 'center'
            this.custom[ee.name] = ee

            if (ee.approve) {
              if (this.showSelect != true) {
                this.showSelect = true
              }
            }
          }

          // header
          if (
            ee.type != 'image' &&
            ee.type != 'expression' &&
            ee.type != 'panel' &&
            ee.type != 'paneldynamic'
          ) {
            oo.push({
              text: ee.name || ee.title || '#',
              value: `answer.${ee.name}`,
              align,
              sortable: false,
            })
          } else {
            oo = this.setHeaders(oo, ee)
          }

          // filter
          if (
            ee.type != 'image' &&
            ee.type != 'expression' &&
            ee.type != 'panel' &&
            ee.type != 'paneldynamic'
          ) {
            this.fields[ee.name] = ee
          }

          // link file
          if (ee.input === 'image' || ee.input === 'file') {
            this.cOrder.push(ee)
          }

          return oo
        }, o)

      return res || o
    },
    _isImg(custom, answer, file) {
      answer = (answer && answer[0]) || {}
      file = (file && file[0]) || {}

      if (custom.input === 'file' || custom.input === 'image') {
        if (file.type && file.type.includes('image')) {
          return true
        } else {
          if (answer.type && answer.type.includes('image')) {
            return true
          }
        }
      }

      return false
    },
    _isFile(custom, answer, file) {
      if (custom.input === 'file' || custom.input === 'image') {
        if (!this._isImg(custom, answer, file) && answer) {
          return true
        }
      }

      return false
    },
    _imgSrc(custom, answer, file) {
      const uri = _.get(file, '[0].uri')
      const b64 = _.get(answer, '[0].content')

      if (uri) {
        return uri
      }
      if (b64) {
        return b64
      }

      return ''
    },
    async _downloadFile(files = []) {
      this.loading = true

      for (let file of files) {
        if (!file.name) {
          continue
        }

        await File.download(file.name)
          .then((res) => {
            const uri = _.get(res, 'data.uri')
            uri && window.open(uri, '_blank')
            return res
          })
          .catch((err) => {
            console.error('Download file err: ', err)
            return err
          })
      }

      this.loading = false
    },
    _filter(cond) {
      this.filter = cond
      this.gets()
      this.dialogFilter = false
    },
    _image(item, custom) {
      const slides = this.cOrder.reduce((o, e) => {
        const files = _.get(item, `files.${e.name}`)

        if (files) {
          for (let i in files) {
            const file = _.get(files, `${i}`)

            if (file.uri) {
              o.push(file.uri)

              if (custom.name === e.name) {
                this.slide = o.length - 1
              }
            }
          }
        }

        return o
      }, [])

      if (slides.length > 0) {
        this.slides = slides

        setTimeout(() => {
          this.dialogImage = true
        }, 100)
      }
    },
    async _edit(item) {
      const survey = await SurveyAPI.editableToken(item.id, {})
      if (survey.token) {
        window.open(
          `${process.env.VUE_APP_SURVEY_URL || ''}/${item.formId}?token=${
            survey.token
          }`,
          '_blank'
        )
      }
    },
    _delete(item) {
      if (item.id) {
        this.confirm = true
        this.form = item
      }
    },
    _confirm() {
      this.loading = true

      SurveyAPI.delete(this.form.id)
        .then(() => {
          this.confirm = false
          this.form = {}
          this.gets()
        })
        .catch((err) => {
          this.confirm = false
          this.form = {}
          this.loading = false
          console.error('Delete survey err: ', err)
        })
    },
    _approve(item, custom) {
      if (this.isViewer) {
        return
      }

      if (!item.approve) {
        item.approve = {}
      }

      if (
        item.approve[custom.name] &&
        item.approve[custom.name].status === true
      ) {
        delete item.approve[custom.name]
        this.approveAction(item)
      } else {
        if (item.id) {
          this.item = {
            data: {
              ...item,
              id: item.id,
              approve: item.approve,
            },
            name: custom.name,
          }
          this.dialogApprove = true
        }
      }
    },
    _reject(item, custom) {
      if (this.isViewer) {
        return
      }

      if (!item.approve) {
        item.approve = {}
      }

      if (
        item.approve[custom.name] &&
        item.approve[custom.name].status === false
      ) {
        delete item.approve[custom.name]
        this.approveAction(item)
      } else {
        if (item.id) {
          this.item = {
            data: {
              id: item.id,
              approve: item.approve,
            },
            name: custom.name,
            reason:
              (item.approve &&
                item.approve[custom.name] &&
                item.approve[custom.name].reason) ||
              this.reason,
          }
          this.dialogReject = true
        }
      }
      this.$refs?.form?.reset()
    },
    _cancelReject() {
      this.dialogReject = false
      this.$refs?.form?.reset()
    },
    _setApproveBulk(status = true, reason = '') {
      return this.selected.reduce((o, e) => {
        e.approve = Object.keys(this.custom).reduce((oo, ee) => {
          if (!oo[ee]) {
            if (this.custom[ee].approve) {
              oo[ee] = {
                status,
                reason,
              }
            }
          }

          return oo
        }, e.approve || {})

        o.push({
          id: e.id,
          approve: e.approve,
        })
        return o
      }, [])
    },
    _approveBulk() {
      const status = true
      const reason = ''
      const data = this._setApproveBulk(status, reason)

      this.saveBulk(data)
    },
    _rejectBulk() {
      const status = false
      const reason = this.item.reason
      const data = this._setApproveBulk(status, reason)

      this.saveBulk(data)
    },
    _dialogApproveBulk() {
      this.dialogApprove = true
      this.item = {
        data: {},
      }
    },
    _dialogRejectBulk() {
      this.dialogReject = true
      this.item = {
        data: {},
        reason: undefined,
      }
    },
    saveBulk(data = []) {
      this.loading = true
      const options = { data }

      SurveyAPI.approveBulk(options)
        .then(() => {
          this.gets()
          this.loading = false
        })
        .catch((err) => {
          this.loading = false
          console.error('Approve survey err: ', err)
        })
    },
    approveAction(item) {
      this.loading = true
      const options = {
        data: {
          approve: item.approve,
        },
      }

      SurveyAPI.approve(item.id, options)
        .then(() => {
          this.gets()
          this.loading = false
        })
        .catch((err) => {
          this.loading = false
          console.error('Approve survey err: ', err)
        })
    },
    approve() {
      const item = this.item

      // Item
      if (item.data && item.data.id) {
        item.data.approve[item.name] = {
          status: true,
          reason: '',
        }
        this.approveAction(item.data)
        // Bulk
      } else {
        this._approveBulk()
      }

      this.dialogApprove = false
    },
    reject() {
      if (!this.valid) {
        return
      }
      const item = this.item

      // Item
      if (item.data && item.data.id) {
        item.data.approve[item.name] = {
          status: false,
          reason: item.reason,
        }
        this.approveAction(item.data)
        // Bulk
      } else {
        this._rejectBulk()
      }

      this.dialogReject = false
      this.$refs?.form?.reset()
    },
    inputType(i) {
      return i.type
    },
    _generateAnswer(custom = {}, answer = {}) {
      const data = answer[custom.name]
      if (!data) {
        return ''
      }

      if (typeof data === 'string') {
        return data
      }

      if (
        (custom.type === 'checkbox' || custom.type === 'tagbox') &&
        Array.isArray(data) &&
        data.length > 0
      ) {
        return data.reduce((o, e) => {
          if (o) {
            o += '<br/>'
          }
          o += e

          const comment = answer[`${custom.name}-Comment`]
          if (custom.hasOther && e === 'other' && comment) {
            o += ` = ${comment}`
          }

          return o
        }, '')
      }

      let header = '',
        body = ''
      if (
        custom.items &&
        Array.isArray(custom.items) &&
        custom.items.length > 0
      ) {
        body = this.generateBodyMultiText(custom.items, data)
      } else {
        header = this.generateHeader(custom.columns)
        body = this.generateBody(custom.rows, custom.columns, data)
      }

      let table = ''
      if (header || body) {
        table = `
          <table class="sub-tb">
            <thead>
              ${header}
            </thead>
            <tbody>
              ${body}
            </tbody>
          </table>
        `
      }

      return table
    },
    generateHeader(columns = []) {
      const max = columns.length - 1
      return columns.reduce((o, e, i) => {
        // First
        if (i === 0) {
          o += '<tr><th></th>'
        }

        // Set values
        o += `<th>${e.title || e.name || e || ''}</th>`

        // Last
        if (i >= max) {
          o += '</tr>'
        }

        return o
      }, '')
    },
    generateBody(rows = [], columns = [], data = []) {
      const objRows = rows.reduce((o, e) => {
        if (e.value && e.text) {
          o[e.value] = e.text
        }
        return o
      }, {})

      const items = Object.keys(data)
      const max = items.length - 1
      return items.reduce((o, e, i) => {
        const item = data[e]

        // First
        o += `<tr><td>${objRows[e] || e}</td>`

        // Set values
        o = columns.reduce((oo, ee) => {
          let answer = ''
          if (item[ee.name || ee.title || ee]) {
            answer = item[ee.name || ee.title || ee]
          } else {
            if (item === ee) {
              answer = item
            }
          }
          oo += `<td>${answer}</td>`
          return oo
        }, o)

        // Last
        if (i >= max) {
          o += '</tr>'
        }

        return o
      }, '')
    },
    generateBodyMultiText(rows = [], data = {}) {
      return rows.reduce((o, e) => {
        o += `<tr><td>${(e.title && e.title.th) ||
          e.title ||
          e.name ||
          e ||
          ''}</td><td>${(data && data[e.name]) || ''}</td></tr>`

        return o
      }, '')
    },
    _exportExcel() {
      this.exportOptions = {
        ...this.exportOptions,
        modal: true,
      }
    },
    _export(type = 'excel') {
      const page = _.get(this, 'options.page', 1)
      const perpage = _.get(this, 'options.itemsPerPage', 10)

      this.export(type, page, perpage)
    },
    export(type = 'excel', page, perpage) {
      const FileDownload = require('js-file-download')
      this.loading = true

      const filter = this.params(false)
      const params = {
        ...filter,
        page,
        perpage,
      }
      if (filter.perpage === 0) {
        params.perpage = filter.perpage
      }

      const options = {
        responseType: 'arraybuffer',
        params,
      }

      let api
      let tfile = 'xlsx'
      if (type === 'json') {
        tfile = type
        api = SurveyAPI.json(this.id, options)
      } else {
        options.params.options = this.exportOptions
        delete options.params.options.modal
        api = SurveyAPI.export(this.id, options)
      }

      if (api) {
        api
          .then((res) => {
            const p = Math.ceil(this.countItems / perpage)
            if (p > page && !(params.start || params.end)) {
              this.export(type, page + 1, perpage)
            }

            // Range
            let start = (page - 1) * perpage + 1
            let end = start - 1 + perpage
            start = (params.start > 0 && params.start) || start
            end = (params.end > 0 && params.end) || end

            const range = `${start}-${end || 'latest'}`

            // File
            FileDownload(
              res,
              `${this.projName}_${this.formName}_${range}.${tfile}`
            )
            this.loading = false
          })
          .catch((err) => {
            this.loading = false
            console.error('Export json survey err: ', err)
          })
      }
    },
    createdToDate(date) {
      if (!date) return
      return moment(date, 'YYYY-MM-DDTHH:mm:ss.SSS[Z]')
        .add(7, 'hour')
        .format('DD MMM YYYY')
    },
    createdToTime(date) {
      if (!date) return
      return moment(date, 'YYYY-MM-DDTHH:mm:ss.SSS[Z]')
        .add(7, 'hour')
        .format('HH:mm:ss')
    },
  },
}
</script>

<style scoped>
.v-list-item__icon {
  margin: 8px 32px 8px 0 !important;
}

.image {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100px;
  min-height: 120px;
  width: 100%;
  height: 100%;
  padding: 10px 0;
}

.overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  transition: 0.3s ease;
}

.items-icon {
  background-color: white;
  border-radius: 20px;
  box-shadow: 2px 2px 1px 0px rgba(0, 0, 0, 0.75);
}

.unactive {
  color: #888888 !important;
}

.image-slide {
  max-height: 100%;
  max-width: 100%;
}
</style>
